import { connect } from 'react-redux';
import Home from './Home';
import { actions } from '../../../redux/modules/home'

const ms2p = (state) => {
    return {
        ...state.home
    };
};
const md2p = {
    ...actions
};

export default connect(ms2p, md2p)(Home);
