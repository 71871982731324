import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import RegisterForm from "./RegisterForm";
import LoadMask from "Utils/LoadMask/LoadMask";
import "./register";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "react-google-login";

class Registro extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    componentDidMount(props) {
        this.state = { prueba: true };
    }

    registerSocial = (response, provider) => {
        this.props.onRegisterSocial(response, this.props.location, provider);
    };

    render() {
        const { onSubmit, loader } = this.props;
        if (localStorage.getItem("token")) {
            return <Redirect to="/" />;
        }

        const googleResponse = (response) => {
            console.log(response);
        };
        return (
            <div className="register-bg">
                <div class="row justify-content-md-center h-100">
                    <div className="col-12 col-md-6 col-lg-6 d-flex align-items-center justify-content-center">
                        <div className="card card-register">
                            <LoadMask loading={loader} light>
                                <div className="card-header">
                                    <img
                                        className="icon-register"
                                        src={require("assets/img/icons/registro.png")}
                                        alt="icon-login"
                                    />
                                    <div className="card-title">REGISTRO</div>
                                </div>
                                <div className="card-body">
                                    <div className="txt-register">
                                        Registrate con tus redes sociales
                                    </div>
                                    <div className="container-btn-redes">
                                        <FacebookLogin
                                            id="BTN-REDES"
                                            cssClass="btn-facebook"
                                            icon="fa-facebook"
                                            textButton="Facebook"
                                            appId="659041564711804"
                                            fields="name,email,picture"
                                            callback={(response) =>
                                                this.registerSocial(
                                                    response,
                                                    "facebook"
                                                )
                                            }
                                        />

                                        <GoogleLogin
                                            className="btn-google"
                                            clientId="341911903323-ep2po60rc66g00vrjpofuq8pahliofb2.apps.googleusercontent.com"
                                            onSuccess={(response) =>
                                                this.registerSocial(
                                                    response,
                                                    "google"
                                                )
                                            }
                                            onFailure={googleResponse}
                                        >
                                            <i className="fa fa-google"></i>
                                            <span>Google</span>
                                        </GoogleLogin>
                                    </div>
                                    <div className="txt-register mb-3">
                                        o registrate con tu correo
                                    </div>
                                    <RegisterForm onSubmit={onSubmit} />
                                    <div className="d-flex align-items-center justify-content-center mt-3">
                                        ¿Ya tienes cuenta?&nbsp;
                                        <Link
                                            to="/login"
                                            className="txt-ingresa"
                                        >
                                            Ingresa aquí
                                        </Link>
                                    </div>
                                </div>
                            </LoadMask>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Registro;
