import React from "react";
import LoadMask from "Utils/LoadMaskTO";
import Modal from "react-responsive-modal";
import EditarPerfilForm from "./EditarPerfilForm";
import ChangePasswordForm from "./ChangePasswordForm";

export const PerfilComponent = (props) => {
    //  State
    const { usuario, modal_perfil, loader, modal_password } = props;
    //  Bind
    const {
        closeModal,
        openModal,
        update,
        closeModalPassword,
        openModalPassword,
        changePassword,
    } = props;

    return (
        <div>
            <div className="card">
                <LoadMask loading={loader} dark blur>
                    <div className="card-header">
                        <div className="card-title d-flex flex-column flex-md-row flex-lg-row align-items-center justify-content-between">
                            <p className="flex1">Datos personales</p>

                            <div className="container-btn-datos-personales">
                                {usuario &&
                                    usuario.red_social ==
                                        "Correo electrónico" && (
                                        <button
                                            type="button"
                                            className="btn btn-primary mb-1 mr-2"
                                            onClick={openModalPassword}
                                        >
                                            Cambiar contraseña
                                        </button>
                                    )}
                                <button
                                    type="button"
                                    className="btn btn-editar mb-1"
                                    onClick={openModal}
                                >
                                    Editar
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="card-body row">
                        <div className="col-12 col-md-6 col-lg-3">
                            <label className="titulo-info">Correo</label>
                            {usuario ? (
                                <p className="txt-info">{usuario.email}</p>
                            ) : (
                                <p className="txt-info">-----</p>
                            )}
                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                            <label className="titulo-info">Nombre</label>
                            {usuario ? (
                                <p className="txt-info">{usuario.first_name}</p>
                            ) : (
                                <p className="txt-info">-----</p>
                            )}
                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                            <label className="titulo-info">Apellido</label>
                            {usuario ? (
                                <p className="txt-info">{usuario.last_name}</p>
                            ) : (
                                <p className="txt-info">-----</p>
                            )}
                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                            <label className="titulo-info">Teléfono</label>
                            {usuario ? (
                                <p className="txt-info">{usuario.telefono}</p>
                            ) : (
                                <p className="txt-info">-----</p>
                            )}
                        </div>
                    </div>
                </LoadMask>
            </div>

            {/* MODAL EDITAR PERFIL */}
            <Modal
                showCloseIcon={false}
                open={modal_perfil}
                onClose={closeModal}
                classNames={{ modal: "modal-sm" }}
            >
                <div style={{ padding: "1.2rem" }}>
                    <LoadMask loading={loader} dark blur>
                        <div className="modal-perfil-title">
                            Datos personales
                        </div>
                        <EditarPerfilForm
                            onSubmit={update}
                            closeModal={closeModal}
                            initialValues={
                                usuario && {
                                    first_name: usuario.first_name,
                                    last_name: usuario.last_name,
                                    email: usuario.email,
                                    telefono: usuario.telefono,
                                }
                            }
                        />
                    </LoadMask>
                </div>
            </Modal>

            {/* MODAL CAMBIO DE CONTRASEÑA */}
            <Modal
                showCloseIcon={false}
                open={modal_password}
                onClose={closeModal}
                classNames={{ modal: "modal-sm" }}
            >
                <div style={{ padding: "1.2rem", maxWidth: "400px" }}>
                    <LoadMask loading={loader} dark blur>
                        <div className="modal-perfil-title">
                            Cambio de contraseña
                        </div>
                        <ChangePasswordForm
                            onSubmit={changePassword}
                            closeModal={closeModalPassword}
                        />
                    </LoadMask>
                </div>
            </Modal>
        </div>
    );
};
