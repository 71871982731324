import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField } from "Utils/renderField/renderField";

const DatosPerfilForm = (props) => {
    const { handleSubmit, setRegistroCompleto } = props;
    return (
        <form name="datosPerfilForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="form-group has-feedback">
                <label htmlFor="first_name">Nombre</label>
                <Field name="first_name" label="Nombre" component={renderField} type="text" className="form-control" />
            </div>
            <div className="form-group has-feedback">
                <label htmlFor="last_name">Apellido</label>
                <Field name="last_name" label="Apellido" component={renderField} type="text" className="form-control" />
            </div>
            <div className="form-group has-feedback">
                <label htmlFor="telefono">Teléfono</label>
                <Field name="telefono" label="Teléfono" component={renderField} type="text" className="form-control" />
            </div>
            <div className="buttons-box">
                <button type="submit" className="btn btn-login m-1 align-self-center">Guardar</button>
                <button type="button" className="btn btn-txt" onClick={() => setRegistroCompleto(true)}>Omitir paso</button>
            </div>
        </form>
    )
}

export default reduxForm({
    form: 'datosPerfilForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            first_name: validators.exists()('Este campo es requerido')
        });
    },
})(DatosPerfilForm);