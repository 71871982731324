import React, { Component } from "react";
import 'rc-slider/assets/index.css';
import './detalle.css';
import '../Utils/Toolbar/toolbar.css';
import { RenderCurrencySimple } from "../Utils/renderField/renderReadField";
import DetalleForm from "./DetalleForm";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import {Link} from "react-router-dom";

export class Detalle extends Component {
    state = {
        img: "",
        images: [],
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== prevState) {
            const { detProd } = prevProps;
            if (detProd && detProd.producto && detProd.producto.imagen && this.state.img === "") {
                const { imagen, imagen_1, imagen_2, imagen_3, imagen_4, img_thumbnail, img_1_thumbnail, img_2_thumbnail, img_3_thumbnail, img_4_thumbnail } = detProd.producto;
                this.setState({ img: detProd.producto.imagen });
                const images = [];
                imagen && images.push({ original: imagen, thumbnail: img_thumbnail });
                imagen_1 && images.push({ original: imagen_1, thumbnail: img_1_thumbnail });
                imagen_2 && images.push({ original: imagen_2, thumbnail: img_2_thumbnail });
                imagen_3 && images.push({ original: imagen_3, thumbnail: img_3_thumbnail });
                imagen_4 && images.push({ original: imagen_4, thumbnail: img_4_thumbnail });
                this.setState({ images });
            }
        }
    }

    componentWillUnmount() {
        this.props.detallePructoDelet();
    }

    componentWillReceiveProps(nextProps) {
        if(this.props !== nextProps) {
            if(nextProps.empresa && nextProps.detProd.producto) {
                //  Asignacion de titulo de la pagina
                document.title = `${nextProps.empresa.nombre} | ${nextProps.detProd.producto.nombre}`;
            }
        }
    }

    render() {
        const { props: { detProd, empresa, agregarProducto }, state: { img, images } } = this;
        const familias = detProd ? detProd.familias : [];

        const submit = (data) => {
            const { cantidad } = data;
            // for (let i = 1; i <= cantidad; i++) agregarProducto(detProd);
            agregarProducto(detProd, parseFloat(cantidad))
        };

        const setImg = e => this.setState({ img: e.target.src });

        return (
            <div className="row">
                {/* ENCABEZADO CON NOMBRE */}
                <div className="container-breadcrumb breadcrumb-to">
                    {
                        empresa && (
                            <nav className="w-100" aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to={`/tienda/${empresa.slug}`}>{empresa.nombre}</Link></li>
                                    {
                                        familias.length > 0 && (
                                            familias[0].map((item, index) => {
                                                return(
                                                    <li key={index} className="breadcrumb-item" aria-current="page"><Link to={`/tienda/${empresa.slug}${item.url_slug}`}>{item.nombre}</Link></li>
                                                )
                                            })
                                        )
                                    }
                                    <li className="breadcrumb-item active" aria-current="page">{detProd.producto ? detProd.producto.nombre : ''}</li>
                                </ol>
                            </nav>
                        )
                    }
                </div>
                {/* Detalle Producto */}
                {
                    detProd.producto && (
                        <div className="container-detalle-tienda">
                            <div className="row">
                                <div className="col-lg-8 col-md-12 col-12 content-img" style={{ textAlign: "right" }}>
                                    <ImageGallery
                                        items={images}
                                        showPlayButton={false}
                                        autoPlay={true}
                                        slideDuration={225}
                                        renderLeftNav={(onclick) => {
                                            return (
                                                <button
                                                  className='image-gallery-custom-left-nav image-gallery-left-nav'
                                                  onClick={onclick}><img src={require("assets/img/icons/arrow-left.png")}/></button>
                                              )
                                        }}
                                        renderRightNav={(onclick) => {
                                            return (
                                                <button
                                                  className='image-gallery-custom-right-nav image-gallery-right-nav'
                                                  onClick={onclick}><img src={require("assets/img/icons/arrow-right.png")}/></button>
                                              )
                                        }}
                                        renderFullscreenButton={(onclick) => {
                                            return (
                                                <button className="gallery-fullscreen-custom-button image-gallery-fullscreen-button" onClick={onclick}>
                                                    <img src={require("assets/img/icons/arrows.png")}/>
                                                </button>
                                            )
                                        }}
                                    />
                                </div>
                                <div className="col-lg-4 col-md-12 col-12 content-img-text" style={{ textAlign: "left" }}>
                                    <h3 className="detalle-titulo-producto">{detProd && detProd.producto && detProd.producto.nombre}</h3>
                                    <h5 className="marca-producto">{detProd && detProd.producto && detProd.producto.marca}</h5>
                                    {/* eslint-disable-next-line max-len */}
                                    <RenderCurrencySimple className="modea-Detalle" value={detProd.precio_online ? detProd.precio_online : 0} />
                                    <br />
                                    <br />
                                    <DetalleForm
                                        detProd={detProd}
                                        onSubmit={submit}
                                        initialValues={{ cantidad: 1 }}
                                    />
                                    {/* {empresa && (
                                        <Link to={`/tienda/${empresa.slug}`} className="mt-2 btn btn-secondary btn-pago btn-lg mb-2 d-none d-md-flex d-lg-flex justify-content-center align-items-center" style={{height: "35px"}}>VOLVER A LA TIENDA</Link>
                                    )} */}
                                </div>
                            </div>
                        </div>
                    )
                }

                {/* SIN RESULTADO */}
                {
                    !detProd.producto && (
                        <div className="col-12 d-flex flex-column align-items-center justify-content-center">
                            <img src={require("assets/img/product-not-found.png")} alt="" className="img-fluid" style={{maxHeight: "663px"}}/>
                            <h5 className="font-weight-bold negro">Sin resultado</h5>
                        </div>
                    )
                }
            </div>
        );
    }
}

export default Detalle;
