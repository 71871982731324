import './estado_compra.css'
import { icons } from "icons";
import BoletaForm from './BoletaForm';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import LoadMask from "Utils/LoadMaskTO";
import Navbar from '../Navbar'
import Footer from '../Footer';
import { RenderDateTime } from "Utils/renderField/renderReadField";
import { RenderMoneda, RenderNumero } from "./camposDetalle";
class DetalleEstadoCompra extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modal_boleta: false,
        }
    }

    openModalPago = () => {
        const { detalle } = this.props;
        if (detalle.forma_pago == "EFE" || detalle.forma_pago == "PCE" || detalle.forma_pago == "PT") {
            const { detalle } = this.props;
            this.props.corfirmarPago(detalle.id)
        }else {
            this.props.setModalPago(true);
        }
    }
    closeModalPago = () => {
        this.props.setModalPago(false);
    }

    componentWillMount() {
        console.log(this.props.match.params.codigo)
        this.props.cargarOrden(this.props.match.params.codigo);
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.detalle !== nextProps.detalle) {
            //  Asignacion de titulo de la pagina
            document.title = nextProps.detalle.codigo ? `Estado de compra | ${nextProps.detalle.codigo}` : "Compralo.gt";
        }
    }

    formaPago(forma_pago){

        if (forma_pago==="EFE"){
            return ("Efectivo");
        }else if (forma_pago==="TAR"){
            return ("Tarjeta");
        }else if (forma_pago==="CHE"){
            return ("Cheque");
        }else if (forma_pago==="DEP"){
            return ("Deposito");
        }else if (forma_pago==="TRA"){
            return ("Transferencia");
        }else if (forma_pago==="TRE"){
            return ("Transferencia electronica");
        }else if (forma_pago==="PCE"){
            return ("Pago contra entrega");
        }else if (forma_pago==="PT"){
            return ("Pago en tienda");
        }
    }

    pagoSubmit = () => {
        const { detalle } = this.props;
        this.props.corfirmarPago(detalle.id);
    }

    render() {

        // state
        const { loader, detalle, modal_pago } = this.props;

        return (
            <div id="estado-compra">
                <Navbar mostrar_iconos={false}/>
                {/* {
                    detalle.sucursal && (
                    )
                } */}
                <div className="row my-5">
                    {/* ENCABEZADO FIN */}
                    <div className="form-container">
                        <LoadMask loading={loader} light blur radius>
                            <div className="container-info">
                                <div className="grid-titulo">Datos personales</div>
                                <div className="p-sm-0 pt-sm-1 pb-sm-1 mt">
                                    <div className="row">
                                        <div className="col-12 col-md-6 col-lg-4 form-group">
                                            <label className="label-view">Nombre</label>
                                            <div className="input-view">
                                                {detalle.nombre}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-4 form-group">
                                            <label className="label-view">Apellido</label>
                                            <div className="input-view">
                                                {detalle.apellido}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-4 form-group">
                                            <label className="label-view">Dirección</label>
                                            <div className="input-view">
                                                {detalle.direccion}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-4 form-group">
                                            <label className="label-view">Correo</label>
                                            <div className="input-view">
                                                {detalle.correo}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-4 form-group">
                                            <label className="label-view">Departamento</label>
                                            <div className="input-view">
                                                {detalle.departamento}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-4 form-group">
                                            <label className="label-view">Municipio</label>
                                            <div className="input-view">
                                                {detalle.municipio}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-4 form-group">
                                            <label className="label-view">Telefono</label>
                                            <div className="input-view">
                                                {detalle.telefono}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-4 form-group">
                                            <label className="label-view">Nit factura</label>
                                            <div className="input-view">
                                                {detalle.nit}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-4 form-group">
                                            <label className="label-view">Nombre para factura</label>
                                            <div className="input-view">
                                                {detalle.nombre_factura}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    detalle.notas && detalle.notas.length > 0 && (
                                        <div>
                                            <div className="grid-titulo">Notas</div>
                                            <div className="col-12 col-md-12 col-lg-10 form-group mt-3">
                                                <ul className="list-notas">
                                                    {
                                                        detalle.notas.map(item => {
                                                            return(
                                                                <li  key={item.id}>
                                                                    <small className="notes-header"><span>{item.nombre_usuario}</span><RenderDateTime value={item.creado}/></small>
                                                                    {item.texto}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    )
                                }

                                <br/>
                                <div className="grid-titulo">Detalles del pedido</div>
                                {/* PRODUCTOS */}
                                {
                                    detalle.detalles && (
                                        <table className="table table-detalle-orden-online my-5">
                                            <thead>
                                                <tr className="m-0 font-weight-bold">
                                                    <th scope="col">Producto</th>
                                                    <th scope="col" className="text-center">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* PRODUCTOS */}
                                                {
                                                    detalle.detalles.map((item, index) => {
                                                        return(
                                                            <tr key={item.id}>
                                                                <td>
                                                                    <div style={{minHeight: "78px"}} className="orden-online-seccion-img d-flex flex-column flex-lg-row justify-content-center justify-content-lg-start align-content-center">
                                                                        <div className=" d-flex flex-column align-items-center justify-content-center">
                                                                            <img
                                                                                style={{ height: "50px", width: "50px", marginTop: "auto", marginBottom: "auto" }}
                                                                                src={item.fraccion.producto.img_cropped ? item.fraccion.producto.img_cropped : icons.img_default}
                                                                                className="d-inline-block border-gris" alt="" />
                                                                        </div>
                                                                        <div className="ml-0 ml-lg-4 d-flex flex-column align-items-center justify-content-center">
                                                                            <b>{item.producto}</b>
                                                                            <small>{item.fraccion.nombre}</small>
                                                                        </div>
                                                                        <span className="d-none d-lg-block">&nbsp;&nbsp;&nbsp;</span>
                                                                        <div className="ml0 ml-lg-4 d-flex align-items-center justify-content-center">
                                                                            <RenderNumero value={item.cantidad}/>
                                                                            &nbsp;
                                                                            X
                                                                            &nbsp;
                                                                            <RenderMoneda className="mr-2" monto={item.precio} pais="GT" />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div style={{height: "78px"}} className="d-flex align-items-center">
                                                                        <RenderMoneda className="mr-2" monto={item.precio * item.cantidad} pais="GT" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                <tr>
                                                    <td className=" font-weight-bold">Sub total</td>
                                                    <td>
                                                        <RenderMoneda className="mr-2" monto={detalle.total - detalle.total_envio} pais="GT" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className=" font-weight-bold">Envío</td>
                                                    <td>
                                                        <RenderMoneda className="mr-2" monto={detalle.total_envio} pais="GT" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="font-weight-bold">Forma de pago</td>
                                                    <td>
                                                        <div>{this.formaPago(detalle.forma_pago)}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="font-weight-bold">Estado de pago</td>
                                                    <td>
                                                        {detalle.pagada ? (
                                                            <div className="text-finish">Realizado</div>
                                                        ) : (
                                                                <div className="text-pending">Pendiente</div>
                                                            )}
                                                        {(!detalle.pagada &&  (detalle.forma_pago != "EFE" &&  detalle.forma_pago != "PCE" && detalle.forma_pago != "PT")) &&  (
                                                            <button onClick={() => this.openModalPago()}

                                                                className="btn btn-outline-primary"
                                                                style={{
                                                                    height: "30px",
                                                                    marginTop: "4px"
                                                                }}><i
                                                                    aria-hidden="true" />Reportar pago</button>
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr className="negro">
                                                    <td className="font-weight-bold">Estado de envío</td>
                                                    <td>
                                                        {detalle.enviada ? (
                                                            <div className="text-finish">Realizado</div>
                                                        ) : (
                                                                <div className="text-pending">Pendiente</div>
                                                            )}
                                                    </td>
                                                </tr>
                                                <tr className="negro">
                                                    <td className="font-weight-bold">No de guía</td>
                                                    <td>
                                                        {detalle.info_envio ? (<div>{detalle.info_envio}</div>) : (<div>Sin información</div>)}
                                                    </td>
                                                </tr>
                                                <tr className="negro">
                                                    <td className="font-weight-bold">Dirección de envío</td>
                                                    <td>
                                                        <div>{detalle.direccion}</div>
                                                    </td>
                                                </tr>
                                                <tr className="row-total">
                                                    <td className="font-weight-bold">Total</td>
                                                    <td>
                                                        <RenderMoneda className="mr-2" monto={detalle.total} pais="GT" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    )
                                }
                            </div>
                        </LoadMask>

                        {/* MODAL DE PAGO */}
                        <Modal showCloseIcon={false} open={modal_pago} onClose={this.closeModalPago} classNames={{ modal: "modal-sm" }} >
                            <div style={{ padding: '1.2rem', maxWidth: '400px' }}>
                                <div className="row">
                                    <div className="col-12 grid-titulo">Reportar pago</div>
                                    <BoletaForm
                                        loader={loader}
                                        onSubmit={this.pagoSubmit}
                                        close={this.closeModalPago}
                                        forma_pago={detalle ? detalle.forma_pago: ""}
                                    />
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>

                {/* FOOTER */}
                <Footer/>
            </div>
        )
    }
}

export default DetalleEstadoCompra
