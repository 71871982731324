import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { RenderCurrencySimple } from '../Utils/renderField/renderReadField';
import _ from 'lodash';
import {Link} from "react-router-dom";

let PasoTresForm = (props) => {
    const { empresa, valores, productos, totalQ_productos, previousStep, handleSubmit } = props;
    const { metodo_entrega_precio, metodo_pago_tipo } = props;
    return (
        <div className="row">
            <div className="form-container mb-5">
                <form className="container-form form-group" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                {/* DATOS PERSONALES */}
                                <div className="grid-titulo d-flex flex-column flex-md-row flex-lg-row align-items-center justify-content-between mb-3 pb-1">
                                    <span>Resumen</span>
                                    {empresa && (
                                        <Link to={`/tienda/${empresa.slug}`} className="btn-volver-carrito mb-2 d-none d-md-flex d-lg-flex">Continuar comprando</Link>
                                    )}
                                </div>
                                <div className="row">
                                    <div className="form-group col-12 col-md-6 col-lg-4">
                                        <label className="label-view">Nombre</label>
                                        <div className="input-view">
                                            {valores.nombre}
                                        </div>
                                    </div>
                                    <div className="form-group col-12 col-md-6 col-lg-4">
                                        <label className="label-view">Apellido</label>
                                        <div className="input-view">
                                            {valores.apellido}
                                        </div>
                                    </div>
                                    <div className="form-group col-12 col-md-6 col-lg-4">
                                        <label className="label-view">Dirección</label>
                                        <div className="input-view">
                                            {valores.direccion}
                                        </div>
                                    </div>
                                    <div className="form-group col-12 col-md-6 col-lg-4">
                                        <label className="label-view">Correo</label>
                                        <div className="input-view">
                                            {valores.correo}
                                        </div>
                                    </div>
                                    <div className="form-group col-12 col-md-6 col-lg-4">
                                        <label className="label-view">Departamento</label>
                                        <div className="input-view">
                                            {valores.departamento}
                                        </div>
                                    </div>
                                    <div className="form-group col-12 col-md-6 col-lg-4">
                                        <label className="label-view">Municipio</label>
                                        <div className="input-view">
                                            {valores.municipio}
                                        </div>
                                    </div>
                                    <div className="form-group col-12 col-md-6 col-lg-4">
                                        <label className="label-view">Teléfono</label>
                                        <div className="input-view">
                                            {valores.telefono}
                                        </div>
                                    </div>
                                    <div className="form-group col-12 col-md-6 col-lg-4">
                                        <label className="label-view">NIT Factura</label>
                                        <div className="input-view">
                                            {valores.nit}
                                        </div>
                                    </div>
                                    <div className="form-group col-12 col-md-6 col-lg-4">
                                        <label className="label-view">Nombre para factura</label>
                                        <div className="input-view">
                                            {valores.nombreFactura}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 p-0">
                            <div className="form-group">
                                {/* DATOS PERSONALES */}
                                <div className="grid-titulo padding-15 mb-3">
                                    Detalles del pedido
                                </div>
                                <table className="table table-detalle-pedido my-5">
                                    <thead>
                                        <tr>
                                            <th scope="col">Producto</th>
                                            <th scope="col" className="text-center">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { productos.map(fraccion => {
                                            return (
                                                <tr key={fraccion.id}>
                                                    <td>
                                                        <div style={{minHeight: "78px"}} className="orden-online-seccion-img d-flex flex-column flex-lg-row justify-content-center justify-content-lg-start align-content-center">
                                                            <div className=" d-flex flex-column align-items-center justify-content-center">
                                                                <img
                                                                    style={{ height: "50px", width: "50px", marginTop: "auto", marginBottom: "auto" }}
                                                                    src={fraccion.producto.img_cropped}
                                                                    className="d-inline-block border-gris" alt="" />
                                                            </div>
                                                            <div className="ml-0 ml-lg-4 d-flex flex-column align-items-center justify-content-center">
                                                                <b>{fraccion.producto.nombre}</b>
                                                                <small>{fraccion.nombre}</small>
                                                            </div>
                                                            <span className="d-none d-lg-block">&nbsp;&nbsp;&nbsp;</span>
                                                            <div className="ml0 ml-lg-4 d-flex align-items-center justify-content-center">
                                                                {fraccion.cantidad}
                                                                &nbsp;
                                                                X
                                                                &nbsp;
                                                                <RenderCurrencySimple value={fraccion.precio_online} />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div style={{height: "78px"}} className="d-flex align-items-center">
                                                            <RenderCurrencySimple value={fraccion.precio_online * fraccion.cantidad} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }) }
                                        <tr>
                                            <td>Subtotal</td>
                                            <td className="text-left text-wrap"><RenderCurrencySimple value={totalQ_productos} /></td>
                                        </tr>
                                        <tr>
                                            <td>Envío:</td>
                                            <td className="text-left text-wrap"><RenderCurrencySimple value={metodo_entrega_precio} /></td>
                                        </tr>
                                        <tr>
                                            <td>Forma de pago</td>
                                            <td className="text-left text-wrap ">{metodo_pago_tipo}</td>
                                        </tr>
                                        <tr>
                                            <td>Estado pago</td>
                                            <td className="text-left text-wrap " style={{color: "#FB5607"}}>Pendiente</td>
                                        </tr>
                                        <tr>
                                            <td>Estado envío</td>
                                            <td className="text-left text-wrap " style={{color: "#FB5607"}}>Pendiente</td>
                                        </tr>
                                        <tr className="row-total">
                                            <td>Total a pagar</td>
                                            <td className="text-left text-wrap "><RenderCurrencySimple value={totalQ_productos + metodo_entrega_precio} /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-md-row flex-lg-row justify-content-center btn-form">
                        <button className="btn btn-secondary btn-pago btn-lg mx-3 my-2 my-lg-0" onClick={previousStep}>Anterior</button>
                        <button type="submit" className="btn-comprar-carrito btn-pago btn-lg mx-3 my-2 my-lg-0">Comprar</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

PasoTresForm = reduxForm({
    form: 'PagoForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(PasoTresForm);

PasoTresForm = connect(state => {
    const store = state.pago;
    const metodos_entrega = store.metodos_entrega ? store.metodos_entrega : [];
    const metodos_pago = store.metodos_pago ? store.metodos_pago : [];
    const storeCarrito = state.carrito_compras;
    const empresa = storeCarrito ? storeCarrito.empresa : null;
    const form = state.form.PagoForm;
    const valores = form.values ? form.values : {};
    const productos = [];
    let totalQ_productos = 0, metodo_entrega_precio = 0, metodo_pago_tipo = '';
    if (form.values) {
        const { values } = form;
        // Se excluyen productos que tengan cantidad cero
        values.productos && values.productos.forEach(producto => {
            if (producto.cantidad > 0) {
                productos.push(producto);
                totalQ_productos+= producto.cantidad * producto.precio;
            }
        });
        if (values.metodo_entrega) {
            metodo_entrega_precio = _.find(metodos_entrega, { id: parseInt(values.metodo_entrega) }).precio_empresa;
        } if (values.metodo_pago) {
            metodo_pago_tipo = _.find(metodos_pago, { prefijo: values.metodo_pago}).prefijo;
        }
    }
    return {
        empresa,
        valores,
        productos,
        totalQ_productos,
        metodo_entrega_precio,
        metodo_pago_tipo,
    };
})(PasoTresForm);

export default PasoTresForm;
