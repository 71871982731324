import React from "react";
import './listado.css';
import { icons } from "icons";
import 'rc-slider/assets/index.css';
import '../Utils/Toolbar/toolbar.css';
import {Link} from "react-router-dom";
import Slider, { Range } from 'rc-slider';
import Pagination from "react-js-pagination";
import ArbolCategorias from "../ArbolCategorias"
import { RenderCurrencySimple } from "../Utils/renderField/renderReadField";

const banner_default = require('assets/img/banner.png');

const Listado = (props) => {
    const { data, precio_inicial_productos, precio_final_productos, search, searchChange, page, pageChange,
        rango_inicial_productos, rango_final_productos, empresa, categorias, categoria_seleccionada, categorias_activas, url, isTabletDesktop } = props;

    //  Bind
    const { listar, agregarProducto, filtroPrecioInicialChange, filtroPrecioFinalChange, changeCategoria, getCategorias } = props;
    const listado = data && data.results ? data.results : [];

    const onSliderChange = (value) => {
        filtroPrecioInicialChange(value[0]);
        filtroPrecioFinalChange(value[1])
    }

    const filtrarXPrecio = (value) => {
        listar(true);
        getCategorias(categoria_seleccionada)
    }

    return (
        <div className="row">
            {/* ENCABEZADO EMPRESA */}
            <div className="container-banner mb-3">
                {
                    empresa.banner ? (
                        <img id="PORTADA" src={empresa.banner} sizes="100vw" height="516" alt="banner"/>
                    ): (
                        <div className="banner_empty"></div>
                    )
                }

                <div className="container-info-empresa">
                    {/* LOGO */}
                    <div className="col-12 col-md-3 col-lg-2">
                        <div className={`container-logo ${empresa.logo ? '' : 'container-sin-logo'}`}>
                            {
                                empresa.logo ? (
                                        <img src={empresa.logo} className="img-encabezado" alt="logo" />
                                ) : (
                                        <img src={require(`assets/img/compralo_negro.png`)} className="w100 d-inline-block align-top logo-default" alt="Ícono empresa" />
                                    )
                            }
                        </div>
                    </div>

                    {/* DATOS */}
                    <div className="col-12 col-md-9 col-lg-10">
                        <div className="container-datos-empresa">
                            <h4 className="nombre-empresa">{empresa && empresa.nombre ? empresa.nombre : "-----"}</h4>
                            <div className="d-flex flex-row w-100 container-items ">
                                <div className="container-item-datos">
                                    <img src={require(`assets/img/icons/correo-blanco.png`)} className="icono-datos-empresa" alt="correo" />
                                    <span>{empresa && empresa.correo_tienda ? empresa.correo_tienda : '-----'}</span>
                                </div>

                                <div className="container-item-datos">
                                    <img src={require(`assets/img/icons/telefono-blanco.png`)} className="icono-datos-empresa" alt="telefono" />
                                    <span>{empresa && empresa.telefono_tienda ? empresa.telefono_tienda : "-----"}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* LISTADO DE PRODUCTOS */}
            <div className="container-tienda w-100">
                {/* BUSCADOR */}
                {/* <div className="row mb-3 mb-md-5 mb-lg-5 mt-3 mt-md-0 mt-lg-0">
                    <div className="col-12 col-md-12 col-lg-8">
                        <BuscadorTO buscar_tienda={searchChange} search_tienda={search}/>
                    </div>
                </div> */}
                <div className="row mb-5">
                    {/* FILTROS */}
                    {
                        isTabletDesktop && (
                            <div className="col-12 col-md-3 col-lg-2 mb-3 mb-md-0 mb-lg-0 d-none d-md-block d-lg-block">
                                <div className="w-100 mb-5">
                                    <div className="txt-filtro mb-3">Filtrar por precio</div>
                                    <div className="d-flex flex-wrap flex-column">
                                        <span className="slider-text">{`Desde Q.${precio_inicial_productos} a Q.${precio_final_productos}`}</span>
                                        <Range
                                            min={data.min_price > 0 ? Math.ceil(data.min_price) - 1 : data.min_price}
                                            max={data.max_price ? Math.ceil(data.max_price) : 0}
                                            defaultValue={[precio_inicial_productos, precio_final_productos]}
                                            value={[precio_inicial_productos, precio_final_productos]}
                                            tipFormatter={value => `${value}%`}
                                            allowCross={true}
                                            onChange={onSliderChange}
                                            onAfterChange={filtrarXPrecio}
                                        />
                                    </div>
                                </div>
                                <ArbolCategorias
                                    url={url}
                                    categorias={categorias_activas.length ? categorias_activas : categorias}
                                    atras={categoria_seleccionada ? true : false}
                                    categoria_seleccionada={categoria_seleccionada}
                                    en_producto={true}
                                    changeCategoria={changeCategoria}/>
                            </div>
                        )
                    }

                    {/* BUSQUEDA Y PRODUCTOS */}
                    <div className="col-12 col-md-9 col-lg-10">
                        <div className="w-100">
                            {
                                (search != "" && search != null) ? (
                                <div className="titulo pl-3 mb-5">Resultados de la búsqueda &nbsp;<b>'{search}'</b></div>
                                ): (
                                    <div className="titulo pl-3 mb-5">Todos los productos</div>
                                )
                            }
                            <div className="col-12 row px-0">
                                {listado.map((fraccion) => {
                                    return (
                                        <div key={fraccion.id} className="col-12 col-md-4 col-lg-3 d-flex flex-column align-items-center mb-5">
                                            <div id="CARD-PRODUCTO" className="w-100">
                                                <Link className="w-100 d-flex flex-column align-items-center" to={`/producto/${fraccion.slug}/`}>
                                                    <div className="card py-4 px-3">
                                                        {/* <img src="/static/img/logo.png" className="img-encabezado" alt=""/> */}
                                                        <img src={fraccion.producto && fraccion.producto.img_cropped} className="img-fluid" alt={fraccion.nombre}/>
                                                    </div>
                                                </Link>
                                                <div className="card-footer">
                                                    <Link className="w-100 d-flex flex-column" to={`/producto/${fraccion.slug}/`}>
                                                        <h5 className="bold my-2 precio-producto">
                                                            <RenderCurrencySimple value={fraccion.precio_online ? fraccion.precio_online : 0}/>
                                                        </h5>
                                                        <span className="titulo-producto" title={fraccion.producto && fraccion.producto.nombre ? fraccion.producto.nombre : ""}>
                                                            {fraccion.producto && fraccion.producto.nombre ? fraccion.producto.nombre : ""}
                                                        </span>
                                                    </Link>
                                                    <button className="btn-card" onClick={() =>agregarProducto(fraccion)}>
                                                        <img src={require("assets/img/icons/carrito-compras.png")} alt="carrito-compras" width="27"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                                {
                                    listado.length <=0 && (
                                        <div className="col-12 d-flex flex-column align-items-center mb-5">
                                            <span className="negro">Sin resultados</span>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="col-12">
                            <Pagination
                                pageRangeDisplayed={3} 
                                activePage={page}
                                totalItemsCount={data.count ? data.count : 0}
                                onChange={value => {
                                    pageChange(value);

                                    //  Scroll top
                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                }}
                                itemsCountPerPage = {data.page_size ? data.page_size : 10}
                                itemClass="page-item"
                                linkClass="page-link"
                                firstPageText={<img src={require(`assets/img/icons/first-page.png`)} alt=""/>}
                                prevPageText={<img src={require(`assets/img/icons/prev-page.png`)} alt=""/>}
                                nextPageText={<img src={require(`assets/img/icons/next-page.png`)} alt=""/>}
                                lastPageText={<img src={require(`assets/img/icons/last-page.png`)} alt=""/>}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Listado;
