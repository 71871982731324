import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import NumberFormat from 'react-number-format';
import { renderSelectField } from './camposPago'
import { renderField } from '../Utils/renderField/renderField';
import { DEPARTAMENTOS, MUNICIPIOS } from '../Utils/departamentos_municipios';
import _ from "lodash"
import {Link} from "react-router-dom";
import { email, NIT } from "../../../utility/validation";

// Verifica si se muestra la información del banco o no.
// let mostrarBanco = false;

const validate = (values, props) => {
    const errors = {};
    if (!values.nombre) {
        errors.nombre = 'Campo requerido';
    } else if (values.nombre > 149) {
        errors.nombre = 'Máximo de caracteres 150.';
    }
    if (!values.apellido) {
        errors.apellido = 'Campo requerido';
    } else if (values.nombre > 149) {
        errors.apellido = 'Máximo de caracteres 150.';
    }
    if (!values.departamento) {
        errors.departamento = 'Campo requerido';
    }
    if (!values.municipio || values.municipio === "") {
        errors.municipio = 'Campo requerido';
    }
    if (!values.direccion) {
        errors.direccion = 'Campo requerido';
    } else if (values.direccion > 250) {
        errors.direccion = 'Máximo de caracteres 250.';
    }
    if (!values.telefono) {
        errors.telefono = 'Campo requerido';
    }
    if (!values.correo) {
        errors.correo = 'Campo requerido';
    }
    if (values.correo){
        errors.correo = email(values.correo);
    }
    if (values.nit) {
        errors.nit = NIT(values.nit);
    }

    if(!values.metodo_entrega) {
        errors.metodo_entrega = "Campo requerido"
    }
    if (props.metodo_entregaValue === "Recoger en tienda") {
        if(!values.sucursal) {
            errors.sucursal = "Campo requerido"
        }
    }
    if(!values.metodo_pago) {
        errors.metodo_pago = "Campo requerido"
    }
    return errors;
};

let PasoDosForm = (props) => {
    const { handleSubmit, previousStep, sucursales, metodos_entrega, metodos_pago, municipios, municipiosChange, metodo_entregaValue, mostrarBanco } = props;
    const { pagoSelec, entregaSelec, sucursalSelec, empresa} = props;
    const datos_cuenta = _.find(metodos_pago, { prefijo: "DEP" });
    return (
        <div className="row">
            <div className="form-container mb-5">
                <form className="container-form" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="w-100 form-group">
                            {/* DATOS PERSONALES */}
                            <div className="grid-titulo d-flex flex-column flex-md-row flex-lg-row align-items-center justify-content-between mb-3 pb-1">
                                <span>Datos personales</span>
                                {empresa && (
                                    <Link to={`/tienda/${empresa.slug}`} className="btn-volver-carrito mb-2 d-none d-md-flex d-lg-flex">Continuar comprando</Link>
                                )}
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-6 form-group">
                                    <label htmlFor="nombre">Nombre</label>
                                    <div className="d-flex flex-column flex-md-row pr-0">
                                        <div className="w-100 d-flex flex-column">
                                            <Field name="nombre"
                                                component={renderField}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 form-group">
                                    <label htmlFor="apellido">Apellido</label>
                                    <div className="d-flex flex-column flex-md-row pr-0">
                                        <div className="w-100 d-flex flex-column">
                                            <Field name="apellido"
                                                component={renderField}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-6 form-group">
                                    <label htmlFor="departamento">Departamento</label>
                                    <div className="d-flex flex-column flex-md-row pr-0">
                                        <div className="w-100 d-flex flex-column">
                                            <Field key="id" name="departamento"
                                                component={renderSelectField}
                                                options={DEPARTAMENTOS}
                                                valueKey="label"
                                                className="form-control"
                                                onCambio={(nombreDepto) => {
                                                    if (nombreDepto != "" && nombreDepto != null) {
                                                        const depto = DEPARTAMENTOS.find(dep => dep.label == nombreDepto)
                                                        const municipios = MUNICIPIOS.filter(muni => muni.departamento === depto.id);
                                                        municipiosChange(municipios);
                                                    }else {
                                                        municipiosChange([]);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 form-group">
                                    <label htmlFor="municipio">Municipio</label>
                                    <div className="d-flex flex-column flex-md-row pr-0">
                                        <div className="w-100 d-flex flex-column">
                                            <Field key="id" name="municipio"
                                                // component={renderSelectField}
                                                component={renderSelectField}
                                                options={municipios}
                                                valueKey="label"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 form-group">
                                    <label htmlFor="direccion">Dirección</label>
                                    <div className="d-flex flex-column flex-md-row pr-0">
                                        <div className="w-100 d-flex flex-column">
                                            <Field name="direccion"
                                                component={renderField}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 form-group">
                                    <label htmlFor="telefono">Teléfono</label>
                                    <div className="d-flex flex-column flex-md-row pr-0">
                                        <div className="w-100 d-flex flex-column">
                                            <Field name="telefono"
                                                component={renderField}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 form-group">
                                    <label htmlFor="correo">Correo</label>
                                    <div className="d-flex flex-column flex-md-row pr-0">
                                        <div className="w-100 d-flex flex-column">
                                            <Field name="correo"
                                                component={renderField}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 form-group">
                                    <label htmlFor="nit">NIT Factura</label>
                                    <div className="d-flex flex-column flex-md-row pr-0">
                                        <div className="w-100 d-flex flex-column">
                                            <Field name="nit"
                                                component={renderField}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 form-group">
                                    <label htmlFor="nombreFactura">Nombre para factura</label>
                                    <div className="d-flex flex-column flex-md-row pr-0">
                                        <div className="w-100 d-flex flex-column">
                                            <Field name="nombreFactura"
                                                component={renderField}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* FIN DATOS PERSONALES */}

                            {/* METODO DE ENTREGA */}
                            <div className="grid-titulo padding-15 mb-4 mt-3">
                                Método de entrega
                            </div>
                            <div className="row">
                                { metodos_entrega && metodos_entrega.map((item) => (
                                    <div key={item.id} className="col-12 col-md-4 col-lg-3">
                                        <label className="d-flex negro font-weight-normal radio c-radio c-radio-nofont">
                                            <Field name="metodo_entrega"
                                                component="input"
                                                type="radio"
                                                value={`${item.id}`}
                                                />
                                            <span />
                                            <div className="d-flex flex-column justify-content-start container-info-radio">
                                                <div className="title-item">{item.nombre}</div>
                                                <div className="descripcion-item">
                                                    <NumberFormat style={{width: "auto", height: "auto", border: "0", borderRadius: "0", margin: "0"}}
                                                        decimalScale={2} fixedDecimalScale={true}
                                                        value={item.precio_empresa ? item.precio_empresa : 0} thousandSeparator={true} prefix={"Q "} displayType={"text"}
                                                    />
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                )) }

                                {entregaSelec && (
                                    <div className="invalid-feedback d-block fnt-14 ml-3">Campo requerido</div>
                                )}
                            </div>
                            {/* FINAL METODO DE ENTREGA */}

                            {/* SUCURSALES DISPONIBLES */}
                            {/* <div className="grid-titulo-sm padding-15 mb-4 mt-5">
                                Selecciona la sucursal que te quede más cerca
                            </div> */}
                            {
                                metodo_entregaValue === "Recoger en tienda" && (
                                    <div className="grid-titulo padding-15 mb-3 mt-5">
                                        Selecciona la sucursal que te quede más cerca
                                    </div>
                                )
                            }

                            {
                                metodo_entregaValue === "Recoger en tienda" && (
                                    <div className="row">
                                        { sucursales && sucursales.map((item) => (
                                            <div key={item.id} className="col-12 col-md-6 col-lg-6">
                                                <label className="d-flex negro font-weight-normal radio c-radio c-radio-nofont">
                                                    <Field name="sucursal"
                                                        component="input"
                                                        type="radio"
                                                        value={`${item.id}`}
                                                        />
                                                    <span />
                                                    <div className="d-flex flex-column justify-content-start container-info-radio">
                                                        <div className="title-item">{item.nombre}</div>
                                                        <div className="descripcion-item">{item.direccion}</div>
                                                    </div>
                                                </label>
                                            </div>
                                        )) }
                                        {sucursalSelec && (
                                            <div className="invalid-feedback d-block fnt-14 ml-3">Campo requerido</div>
                                        )}
                                    </div>
                                )
                            }
                            {/* FINAL SUCURSALES */}

                            {/* METODO DE PAGO */}
                            <div className="grid-titulo padding-15 mb-3 mt-5">
                                Método de pago
                            </div>
                            <div className="row">
                                { metodos_pago && metodos_pago.map(item => (
                                    <div key={item.id + item.nombre} className="col-12 col-md-6 col-lg-4">
                                        <label className="d-flex negro font-weight-normal radio c-radio c-radio-nofont">
                                            <Field name="metodo_pago"
                                                component="input"
                                                type="radio"
                                                value={`${item.prefijo}`}
                                            />
                                            <span />
                                            <div className="d-flex flex-column justify-content-start container-info-radio">
                                                <div className="title-item">{item.nombre}</div>
                                                <div className="descripcion-item">{item.descripcion}</div>
                                            </div>
                                        </label>
                                    </div>
                                )) }
                                {pagoSelec && (
                                    <div className="invalid-feedback d-block fnt-14 ml-3">Campo requerido</div>
                                )}
                            </div>
                            { (mostrarBanco && datos_cuenta) && (
                                <div className="container-datos-banco d-flex flex-column flex-md-row justify-content-around mt-4">
                                    <div className="">
                                        <label className="label-view">Cuenta de banco</label>
                                        <div className="input-view">
                                            {datos_cuenta.banco_cuenta}
                                        </div>
                                    </div>
                                    <div className="">
                                        <label className="label-view">No. de Cuenta</label>
                                        <div className="input-view">
                                            {datos_cuenta.no_cuenta}
                                        </div>
                                    </div>
                                    <div className="">
                                        <label className="label-view">A nombre de</label>
                                        <div className="input-view">
                                            {datos_cuenta.nombre_cuenta}
                                        </div>
                                    </div>
                                </div>
                            ) }
                            {/* FINAL METODO DE PAGO */}
                            <div className="d-flex flex-column flex-md-row flex-lg-row justify-content-center btn-form mt-5">
                                <button className="btn btn-secondary btn-pago btn-lg mx-3 my-2 my-lg-0" onClick={previousStep}>
                                    Anterior
                                </button>
                                <button type="submit" className="btn-comprar-carrito btn-pago btn-lg mx-3 my-2 my-lg-0">
                                    Continuar con la orden
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

PasoDosForm = reduxForm({
    form: 'PagoForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
})(PasoDosForm);

PasoDosForm = connect(state => {
    const store = state.pago;
    const metodos_entrega = store.metodos_entrega ? store.metodos_entrega : [];
    const metodos_pago = store.metodos_pago ? store.metodos_pago : [];
    const sucursales = store.sucursalesDisponibles ? store.sucursalesDisponibles : [];
    const storeCarrito = state.carrito_compras;
    const empresa = storeCarrito ? storeCarrito.empresa : null;
    const form = state.form.PagoForm;
    let mostrarBanco = false;
    // Se desabilita el botón de pasar al siguiente paso si no se ha seleccionado estos 3 valores
    let pagoSelec = false, entregaSelec = false, sucursalSelec = false;
    let metodo_entregaValue = null;
    if (form.values) {
        const { values, submitFailed } = form;
        pagoSelec = !values.metodo_pago && submitFailed;
        entregaSelec = !values.metodo_entrega && submitFailed;
        sucursalSelec = !values.sucursal && submitFailed;
        if (values.metodo_entrega) {
            const data_metodo_entrega = _.find(store.metodos_entrega, { id: parseInt(values.metodo_entrega) });
            metodo_entregaValue = data_metodo_entrega.nombre;
        }
        if (values.metodo_pago == "DEP"){
            mostrarBanco = true;
        }else {
            mostrarBanco = false;
        }
    }
    return {
        metodos_entrega,
        metodos_pago,
        sucursales,
        pagoSelec,
        entregaSelec,
        sucursalSelec,
        empresa,
        metodo_entregaValue,
        mostrarBanco
    };
})(PasoDosForm);

export default PasoDosForm;
