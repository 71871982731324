import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderNumberConControles } from "../../../../../../frontend/src/js/common/components/Utils/renderField/renderField";

const DetalleForm = (props) => {
    const { handleSubmit, detProd } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div>
                <p className="descripcion-producto">{detProd && detProd.producto && detProd.producto.descripcion}</p>
                <br />
                <div className="control-cantidad">
                    <Field
                        name="cantidad"
                        component={renderNumberConControles}
                        type="text"
                        className="form-control"
                        minValue={0}
                    />
                </div>
                {/* eslint-disable-next-line react/button-has-type */}
                <button className="btn-carrito-detalle" type="submit">
                    Añadir al carrito
                </button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'detProduct',
})(DetalleForm);
