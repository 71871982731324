import "./home.css";
import { Link } from "react-router-dom";
import React, { Component, useEffect, Fragment } from "react";
import LoadMask from "Utils/LoadMaskTO";
import Footer from "../Footer";
import Navbar from "../Navbar";
import Pagination from "react-js-pagination";
import ArbolCategorias from "../ArbolCategorias";

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            width: window.innerWidth,
        };
    }

    componentDidMount() {
        //  Asignacion de titulo de la pagina
        document.title = "COMPRALO.GT";
        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener("resize", this.reportWindowSize);
    }

    componentWillMount() {
        this.props.list();
        this.props.getCategorias();
        //  Asignacion de titulo de la pagina
        document.title = "Compralo.gt";
    }
    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
        window.removeEventListener("resize", this.reportWindowSize);
    }

    reportWindowSize = (event) => {
        this.setState({ width: window.innerWidth });
    };

    handleScroll(event) {
        let header = document.getElementById("NAVBAR-TO");
        if (
            document.body.scrollTop > 20 ||
            document.documentElement.scrollTop > 20
        ) {
            if (header) {
                header.classList.add("sticky");
            }
        } else {
            if (header) {
                header.classList.remove("sticky");
            }
        }
    }

    render() {
        // State
        const {
            data,
            loader,
            page,
            categorias,
            categoria_seleccionada,
        } = this.props;
        const isTabletDesktop = this.state.width > 767;
        //    bind
        const { pageChange, changeCategoria } = this.props;
        const listado = data && data.results ? data.results : [];
        return (
            <div id="HOME">
                <div style={{ backgroundColor: "#F5F5F5" }}>
                    <div className="row">
                        <div className="container-home-info">
                            <div className="w-100 d-flex flex-column flex-lg-row flex-md-row justify-content-between align-items-center">
                                <div className="container-logo-text d-flex flex-column justify-content-center align-items-center">
                                    <img
                                        className="d-inline-block align-top mb-3"
                                        src={require(`assets/img/compralo_negro.png`)}
                                        width="180"
                                        alt=""
                                    ></img>
                                    <h2
                                        className="txt-home-info"
                                        style={{ color: "#282828" }}
                                    >
                                        Bienvenido a la plataforma de tiendas en
                                        línea COMPRALO
                                    </h2>
                                </div>
                                <div className="container-img-home-info">
                                    <img
                                        src={require(`assets/img/tienda-vector.svg`)}
                                        height="300"
                                        className="d-inline-block align-top"
                                        alt=""
                                    ></img>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="w-100 overflow-hidden d-flex flex-column">
                    <img className="img-fluid w-100" src={require(`assets/img/borde-home.png`)} alt=""></img>
                </div> */}

                    {/* BODY */}
                    <div className="container-home-body">
                        <div className="row">
                            {isTabletDesktop && (
                                <div className="col-12 col-md-3 col-lg-3 mb-3 mb-md-0 mb-lg-0">
                                    <div className="w-100">
                                        <ArbolCategorias
                                            categorias={categorias}
                                            categoria_seleccionada={
                                                categoria_seleccionada
                                            }
                                            changeCategoria={changeCategoria}
                                            titulo="Categorías de tiendas"
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="col-12 col-md-9 col-lg-9 p-0">
                                <LoadMask loading={loader} dark blur>
                                    <div className="w-100">
                                        <h4 className="titulo-listado-tiendas mt-4">
                                            Visita nuestras tiendas afiliadas
                                        </h4>
                                        <div className="row">
                                            {listado.map((empresa) => {
                                                return (
                                                    <div
                                                        key={empresa.id}
                                                        className="col-12 col-md-4  col-lg-3 mb-5"
                                                    >
                                                        <div className="card-home d-flex flex-column align-items-center">
                                                            {empresa.logo ? (
                                                                <img
                                                                    className="card-img-top"
                                                                    src={
                                                                        empresa.logo
                                                                    }
                                                                    alt={
                                                                        empresa.nombre
                                                                    }
                                                                    style={{
                                                                        maxWidth:
                                                                            "150px",
                                                                    }}
                                                                ></img>
                                                            ) : (
                                                                <img
                                                                    src={require(`assets/img/Imagen.png`)}
                                                                    style={{
                                                                        maxWidth:
                                                                            "150px",
                                                                    }}
                                                                    className="card-img-top"
                                                                    alt="Ícono empresa"
                                                                />
                                                            )}
                                                            <div className="info-card-home">
                                                                <b
                                                                    className="card-home-title"
                                                                    title={
                                                                        empresa.nombre
                                                                    }
                                                                >
                                                                    {
                                                                        empresa.nombre
                                                                    }
                                                                </b>
                                                                {/* <div className="d-flex flex-nowrap align-items-center font-weight-normal">
                                                                    <img src="/static/img/telefono.png" width="15" height="15" className="d-inline-block align-top" alt="" />
                                                                    &nbsp;&nbsp;
                                                                    <span>{empresa.telefono_tienda != "" && empresa.telefono_tienda != null ? empresa.telefono_tienda : "-----"}</span>
                                                                </div>
                                                                <div className="d-flex flex-nowrap align-items-center font-weight-normal">
                                                                    <img src="/static/img/correo.png" width="15" height="15" className="d-inline-block align-top" alt="" />
                                                                    &nbsp;&nbsp;
                                                                    <span>{empresa.correo_tienda != "" && empresa.correo_tienda != null ? empresa.correo_tienda : "-----"}</span>
                                                                </div> */}
                                                            </div>
                                                            <Link
                                                                style={{
                                                                    width:
                                                                        "85%",
                                                                }}
                                                                className="btn btn-azul btn-lg mt-2 mb-2"
                                                                to={`/tienda/${empresa.slug}`}
                                                            >
                                                                Ver tienda
                                                            </Link>
                                                        </div>
                                                    </div>
                                                );
                                            })}

                                            {data.length <= 0 && (
                                                <div className="col-12 d-flex align-items-center justify-content-center py-5">
                                                    <span
                                                        className="font-weight-bold"
                                                        style={{
                                                            color: "#a1a1a1",
                                                            fontSize: "15px",
                                                        }}
                                                    >
                                                        Sin resultados
                                                    </span>
                                                </div>
                                            )}

                                            <div className="col-12">
                                                <Pagination
                                                    pageRangeDisplayed={3}
                                                    activePage={page}
                                                    totalItemsCount={
                                                        data.count
                                                            ? data.count
                                                            : 0
                                                    }
                                                    onChange={(value) => {
                                                        pageChange(value);

                                                        //  Scroll top
                                                        window.scrollTo({
                                                            top: 0,
                                                            behavior: "smooth",
                                                        });
                                                    }}
                                                    itemsCountPerPage={
                                                        data.page_size
                                                            ? data.page_size
                                                            : 10
                                                    }
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                    firstPageText={
                                                        <img
                                                            src={require(`assets/img/icons/first-page.png`)}
                                                            alt=""
                                                        />
                                                    }
                                                    prevPageText={
                                                        <img
                                                            src={require(`assets/img/icons/prev-page.png`)}
                                                            alt=""
                                                        />
                                                    }
                                                    nextPageText={
                                                        <img
                                                            src={require(`assets/img/icons/next-page.png`)}
                                                            alt=""
                                                        />
                                                    }
                                                    lastPageText={
                                                        <img
                                                            src={require(`assets/img/icons/last-page.png`)}
                                                            alt=""
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </LoadMask>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
