import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import LoginForm from "./LoginForm";
import "./login.css";
import LoadMask from "Utils/LoadMask/LoadMask";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "react-google-login";
class Login extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    componentDidMount(props) {
        this.state = { prueba: true };
    }

    loginSocial = (response, provider) => {
        this.props.onLoginSocial(response, this.props.location, provider);
    };

    submit = (data) => {
        this.props.onSubmit(data, this.props.location);
    };
    render() {
        const { loader } = this.props;
        if (localStorage.getItem("token")) {
            return <Redirect to="/" />;
        }

        const fbResponse = (response) => {
            console.log(response);
        };
        const googleResponse = (response) => {
            console.log(response);
        };
        return (
            <div className="login-bg">
                <div className="row h-100">
                    <div className="col-12 col-md-6 col-lg-6 d-flex align-items-center justify-content-end">
                        <div className="card card-login">
                            <LoadMask loading={loader} light>
                                <div className="card-header">
                                    <img
                                        className="icon-login"
                                        src={require("assets/img/icons/login.png")}
                                        alt="icon-login"
                                    />
                                    <div className="card-title">INGRESAR</div>
                                </div>
                                <div className="card-body">
                                    <div className="txt-login">
                                        Inicia sesión con tus redes sociales
                                    </div>
                                    <div className="container-btn-redes">
                                        <FacebookLogin
                                            cssClass="btn-facebook"
                                            icon="fa-facebook"
                                            textButton="Facebook"
                                            appId="659041564711804"
                                            fields="name,email,picture"
                                            callback={(response) =>
                                                this.loginSocial(
                                                    response,
                                                    "facebook"
                                                )
                                            }
                                        />

                                        <GoogleLogin
                                            className="btn-google"
                                            clientId="341911903323-ep2po60rc66g00vrjpofuq8pahliofb2.apps.googleusercontent.com"
                                            onSuccess={(response) =>
                                                this.loginSocial(
                                                    response,
                                                    "google"
                                                )
                                            }
                                            onFailure={googleResponse}
                                        >
                                            <i className="fa fa-google"></i>
                                            <span>Google</span>
                                        </GoogleLogin>
                                    </div>
                                    <div className="txt-login mb-3">
                                        o inicia sesión con tu correo
                                    </div>
                                    <LoginForm onSubmit={this.submit} />
                                    {/* <span>¿No tienes cuenta?&nbsp;<Link to="/registro">Registrate aquí</Link></span> */}
                                    <div className="txt-olvide">
                                        ¿ Olvide mi contraseña ?
                                    </div>
                                </div>
                            </LoadMask>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 d-flex align-items-center justify-content-start mt-3 mt-md-0 mt-lg-0">
                        <div className="card card-login">
                            <div className="card-header">
                                <img
                                    className="icon-login"
                                    src={require("assets/img/icons/registro.png")}
                                    alt="icon-login"
                                />
                            </div>
                            <div className="card-body">
                                <div className="txt-login">
                                    ¿ Aun no tienes cuenta ?
                                </div>
                                <div className="txt-info mt-3">
                                    Haz click en el botón para empezar a crear
                                    tu cuenta
                                </div>
                                <div className="content-btn-registro">
                                    <Link
                                        to={{
                                            pathname: "/register",
                                            state: {
                                                from: this.props.location.state
                                                    ? this.props.location.state
                                                          .from
                                                    : undefined,
                                            },
                                        }}
                                        className="btn btn-login m-1 align-self-center"
                                    >
                                        Registrarme
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
