import { api } from "api";
import uuidv1 from "uuid/v1"
import Swal from "sweetalert2";
import ToastStore from 'react-toasts'
import { handleActions } from "redux-actions";
import history from "../../../utility/history";
import { registrarEmpresa } from '../carrito_compras/localStorageCarrito';
import { obtenerDatosEmpresaLocal } from '../carrito_compras/carrito_compras';
import {setDataEdicion} from "../../../../../../frontend admin/src/js/redux/modules/bienvenida/bienvenida";

const DATA = 'DATA_PRODUCTOS';
const PAGE = 'PAGE_PRODUCTOS';
const LOADER = 'LOADER_PRODUCTOS';
const SEARCH = 'SEARCH_PRODUCTOS';
const ITEM_DATA = 'ITEM_PRODUCTOS';
const ORDERING = 'ORDERING_PRODUCTOS';
const CATEGORIAS = "CATEGORIAS_PRODUCTOS";
const EMPRESA_PRODUCTOS = 'EMPRESA_PRODUCTOS';
const URL_PRODUCTOS = "URL_PRODUCTOS_PRODUCTOS";
const UUID_REQ_PRODUCTOS = "UUID_REQ_PRODUCTOS";
const DETALLEPRODUCTO = 'PRODUCTO_DETALLEPRODUCTO';
const RANGO_FINAL_PRODUCTOS = 'RANGO_FINAL_PRODUCTOS';
const FILTROS_APLICADOS = "FILTROS_APLICADOS_PRODUCTOS";
const RANGO_INICIAL_PRODUCTOS = 'RANGO_INICIAL_PRODUCTOS';
const CATEGORIAS_ACTIVAS = "CATEGORIAS_ACTIVAS_PRODUCTOS";
const CATEGORIA_SELECCIONADA = 'CATEGORIA_SELECCIONADA_PRODUCTOS';
const FILTRO_PRECIO_FINAL_PRODUCTOS = 'FILTRO_PRECIO_FINAL_PRODUCTOS';
const FILTRO_PRECIO_INICIAL_PRODUCTOS = 'FILTRO_PRECIO_INICIAL_PRODUCTOS';

const endpointCategorias = "categoria";

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

const setDetalleProd = detProd => ({
    type: DETALLEPRODUCTO,
    detProd,
});
const empresaChange = empresa => (dispatch) => {
    dispatch({type: EMPRESA_PRODUCTOS, empresa});
};

const filtroPrecioInicialChange = precio_inicial_productos => (dispatch) => {
    dispatch({type: FILTRO_PRECIO_INICIAL_PRODUCTOS, precio_inicial_productos});
    // dispatch(listar());
};

const filtroPrecioFinalChange = precio_final_productos => (dispatch) => {
    dispatch({type: FILTRO_PRECIO_FINAL_PRODUCTOS, precio_final_productos});
    // dispatch(listar());
};

const rangoPrecioInicialChange = rango_inicial_productos => (dispatch) => {
    dispatch({type: RANGO_INICIAL_PRODUCTOS, rango_inicial_productos});
    // dispatch(listar());
};

const rangoPrecioFinalChange = rango_final_productos => (dispatch) => {
    dispatch({type: RANGO_FINAL_PRODUCTOS, rango_final_productos});
    // dispatch(listar());
};
const setCategorias = categorias =>  ({
    type: CATEGORIAS,
    categorias
});
const setCategoriaSeleccionada = categoria_seleccionada =>  ({
    type: CATEGORIA_SELECCIONADA,
    categoria_seleccionada
});
const setCategoriasActivas = categorias_activas =>  ({
    type: CATEGORIAS_ACTIVAS,
    categorias_activas
});
export const setUuidReqProductos = (uuid_req_productos) => ({
    type: UUID_REQ_PRODUCTOS,
    uuid_req_productos
})
export const setUrlProductos = (url_productos) => ({
    type: URL_PRODUCTOS,
    url_productos
})
export const setFiltrosAplicados = (filtros_aplicados) => ({
    type: FILTROS_APLICADOS,
    filtros_aplicados
})
// ------------------------------------
// Actions
// ------------------------------------

const listar = (filtroPrecio = false) =>  (dispatch, getStore) => {
    dispatch(setData({
        count: 0,
        empresa: [],
        results: [],
    }))
    const resource = getStore().productos;
    const params = {};
    params.page = resource.page;
    params.search = resource.search;
    params.empresa = resource.empresa.id;
    params.page_size = 12;
    if (resource.precio_inicial_productos >= 0 && resource.precio_final_productos > 0) {
        params.precio_inicial = resource.precio_inicial_productos;
        params.precio_final = resource.precio_final_productos;
    }
    if (filtroPrecio === true) {
        params.page = 1;
    }

    //  Se aplica la categoría seleccionada para filtrar los productos
    if (resource.categoria_seleccionada) {
        params.categoria = resource.categoria_seleccionada
    }

    dispatch(setLoader(true));

    const uuid = uuidv1();
    dispatch(setUuidReqProductos(uuid));
    api.get('tienda_linea/listado_productos', params).catch(err => {

    }).then(resp => {
        const otroUuid = getStore().productos.uuid_req_productos;
        if (otroUuid === uuid) {
            dispatch(setData(resp));
            if (!filtroPrecio) {
                dispatch(filtroPrecioInicialChange(params.precio_inicial ? params.precio_inicial : resp.min_price));
                dispatch(filtroPrecioFinalChange(params.precio_final ? params.precio_final : resp.max_price));
            }else {
                dispatch(setPage(1))
                dispatch(filtroPrecioInicialChange(params.precio_inicial ? params.precio_inicial : 0));
                dispatch(filtroPrecioFinalChange(params.precio_final ? params.precio_final : 0));
            }
        }
    }).finally(() => {
        dispatch(setLoader(false));
    })
};

export const asignarEmpresa = (slug_empresa, slug_categoria=null, desde_detalle=false) => (dispatch) => {
    dispatch(setLoader(true));
    dispatch(empresaChange({}));
    api.get('tienda_linea/get_info_empresa', {empresa: slug_empresa}).catch(error => {
        Swal.fire('ERROR', error.detail || "Error al obtener los datos de la empresa, intenta más tarde", "error");
        history.push("/");
    }).then(resp => {
        let empresa = resp;
        registrarEmpresa(empresa);
        dispatch(empresaChange(empresa));
        dispatch(obtenerDatosEmpresaLocal(empresa.slug))
        if (!slug_categoria)
            dispatch(setCategoriaSeleccionada(null))
        else
            dispatch(setCategoriaSeleccionada(slug_categoria))

        //  Asignacion de titulo de la pagina
        document.title = empresa.nombre || "Compralo.gt"
    }).finally(() => {
        dispatch(setLoader(false));
        if(!desde_detalle) {
            dispatch(listar())
            dispatch(getCategorias(slug_categoria))
        }
    })
}

const detalleProducto = slug_producto => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`tienda_linea/detalle`, { slug_producto }).catch(err => {
        Swal.fire('ERROR', err.detail || "Error al obtener los datos del producto, intenta más tarde", "error");
    }).then((response) => {
        dispatch(setDetalleProd(response));
        if (response.empresa) {
            dispatch(asignarEmpresa(response.empresa.slug, null, true))
        }
    }).catch().finally(() => {
        dispatch(setLoader(false));
    });
};

const detallePructoDelet = () => (dispatch) => {
    dispatch(setDetalleProd({}));
}

const searchChange = search => (dispatch, getStore) => {
    const store = getStore();
    const { categoria_seleccionada } = store.productos;
    dispatch(setPage(1))
    dispatch(setSearch(search));
    dispatch(listar());

    dispatch(getCategorias(categoria_seleccionada))
};

const pageChange = page => (dispatch) => {
    dispatch(setPage(page));
    dispatch(listar());
};

//  CATEGORIAS

export const getCategorias = (slug=null) => (dispatch, getStore) => {
    const store = getStore();

    const { empresa, precio_inicial_productos, precio_final_productos, search } = store.productos;
    dispatch(setCategorias([]))
    let params = {empresa: empresa.slug ? empresa.slug : ""}
    if (slug)
        params.categoria = slug
    
    if (precio_inicial_productos >= 0 && precio_final_productos > 0) {
        params.precio_inicial = precio_inicial_productos;
        params.precio_final = precio_final_productos;
    }
    if (search != '' && search != null && search != undefined) {
        params.search = search
    }

    api.get(`${endpointCategorias}/tree_tienda_online`, params).catch(err => {
        console.log("ERROR: ", err);
        ToastStore.error("No fue posible obtener el listado de categorías")
    }).then(resp => {
        if(resp) {
            dispatch(setCategorias(resp.data));
        }
    }).finally(() => {
    })
}

export const changeCategoria = (slug_categoria) => (dispatch) => {
    dispatch(setCategoriaSeleccionada(slug_categoria))
    dispatch(setPage(1))
    dispatch(listar())
    dispatch(getCategorias(slug_categoria))
}

// _____________________________________

export const actions = {
    listar,
    setData,
    setSearch,
    searchChange,
    pageChange,
    asignarEmpresa,
    detalleProducto,
    detallePructoDelet,
    // Filtros
    getCategorias,
    changeCategoria,
    setUrlProductos,
    setFiltrosAplicados,
    filtroPrecioInicialChange,
    filtroPrecioFinalChange,
    rangoPrecioInicialChange,
    rangoPrecioFinalChange,
};


// -----------------------------------
// Reducers
// -----------------------------------

export const reducers = {
    [LOADER]: (state, {loader}) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, {data}) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, {item}) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, {page}) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, {ordering}) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, {search}) => {
        return {
            ...state,
            search,
        };
    },
    [EMPRESA_PRODUCTOS]: (state, {empresa}) => {
        return {
            ...state,
            empresa,
        };
    },
    [FILTRO_PRECIO_INICIAL_PRODUCTOS]: (state, {precio_inicial_productos}) => {
        return {
            ...state,
            precio_inicial_productos,
        };
    },
    [FILTRO_PRECIO_FINAL_PRODUCTOS]: (state, {precio_final_productos}) => {
        return {
            ...state,
            precio_final_productos,
        };
    },
    [RANGO_INICIAL_PRODUCTOS]: (state, {rango_inicial_productos}) => {
        return {
            ...state,
            rango_inicial_productos,
        };
    },
    [RANGO_FINAL_PRODUCTOS]: (state, {rango_final_productos}) => {
        return {
            ...state,
            rango_final_productos,
        };
    },
    [DETALLEPRODUCTO]: (state, {detProd}) => {
        return {
            ...state,
            detProd,
        };
    },
    [CATEGORIAS]: (state, {categorias}) => {
        return {
            ...state,
            categorias,
        };
    },
    [CATEGORIA_SELECCIONADA]: (state, {categoria_seleccionada}) => {
        return {
            ...state,
            categoria_seleccionada,
        };
    },
    [UUID_REQ_PRODUCTOS]: (state, { uuid_req_productos }) => {
        return {
            ...state,
            uuid_req_productos,
        };
    },
    [CATEGORIAS_ACTIVAS]: (state, {categorias_activas}) => {
        return {
            ...state,
            categorias_activas,
        };
    },
    [URL_PRODUCTOS]: (state, {url_productos}) => {
        return {
            ...state,
            url_productos,
        };
    },
    [FILTROS_APLICADOS]: (state, {filtros_aplicados}) => {
        return {
            ...state,
            filtros_aplicados,
        };
    },
}

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    loader: false,
    data: {
        count: 0,
        empresa: [],
        results: [],
    },
    item: {
        results: [],
        count: 0,
    },
    page: 1,
    ordering: '',
    search: '',
    empresa: {},
    precio_inicial_productos: 0,
    precio_final_productos: 0,
    rango_inicial_productos: 0,
    rango_final_productos: 0,
    detProd: {},
    categorias: [],
    categoria_seleccionada: null,
    uuid_req_productos: "",
    categorias_activas: [],
    url_productos: "",
    filtros_aplicados: false
}

export default handleActions(reducers, initialState);
