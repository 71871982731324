import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { reduxForm, Field, FieldArray } from "redux-form";
import { renderNumberConControles } from "../Utils/renderField/renderField";
import {
    RenderCurrencySimple,
    RenderNumber,
} from "../Utils/renderField/renderReadField";

function isAuthenticated() {
    return localStorage.getItem("token");
}

const renderProductos = (props) => {
    const { fields, cambiarCantidad, quitar, empresa, location } = props;
    let total_carrito = 0;
    return (
        <div className="row d-flex justify-content-between mt-4">
            <div className="col-12 col-lg-9 pl-0 px-0 px-md-1 px-lg-1">
                <table className="d-block d-lg-table mb-0 table-carrito">
                    <thead>
                        <tr>
                            <th scope="col">Producto</th>
                            <th
                                scope="col"
                                className="text-right d-none d-md-table-cell d-lg-table-cell"
                            >
                                Precio
                            </th>
                            <th scope="col">Cantidad</th>
                            <th
                                scope="col"
                                className="text-right d-none d-md-table-cell d-lg-table-cell"
                            >
                                Subtotal
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((producto, index) => {
                            const fraccion_obj = fields.get(index);
                            total_carrito +=
                                fraccion_obj.cantidad * fraccion_obj.precio;
                            return (
                                <tr key={fraccion_obj.id}>
                                    <td className="d-flex flex-wrap flex-column flex-md-row flex-lg-row align-items-center py-1">
                                        <div>
                                            <span
                                                className="fa fa-times mr-3 icono-eliminar"
                                                onClick={() => {
                                                    fields.remove(index);
                                                    quitar(index);
                                                }}
                                            />
                                        </div>
                                        <img
                                            src={
                                                fraccion_obj.producto
                                                    .img_cropped
                                            }
                                            className="img-producto-carrito"
                                        />
                                        <span className="negro-dark nombre-producto">
                                            {fraccion_obj.producto.nombre}
                                        </span>
                                        <div className="w-100 d-flex d-md-none d-lg-none align-items-center justify-content-center mt-3">
                                            <RenderNumber
                                                value={fraccion_obj.cantidad}
                                            />{" "}
                                            &nbsp; X &nbsp;{" "}
                                            <RenderCurrencySimple
                                                value={fraccion_obj.precio}
                                            />
                                        </div>
                                    </td>
                                    <td className="align-middle text-right text-nowrap d-none d-md-table-cell d-lg-table-cell">
                                        <RenderCurrencySimple
                                            className="precio-producto-carrito"
                                            value={fraccion_obj.precio}
                                        />
                                    </td>
                                    <td className="align-middle control-cantidad-carrito">
                                        <Field
                                            name={`${producto}.cantidad`}
                                            component={renderNumberConControles}
                                            type="text"
                                            className="form-control"
                                            minValue={0}
                                            onCambio={(val) => {
                                                if (val >= 0) {
                                                    cambiarCantidad(val, index);
                                                }
                                            }}
                                        />
                                    </td>
                                    <td className="align-middle text-right text-nowrap d-none d-md-table-cell d-lg-table-cell">
                                        <RenderCurrencySimple
                                            className="precio-producto-carrito"
                                            value={
                                                fraccion_obj.precio *
                                                fraccion_obj.cantidad
                                            }
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                        {fields.length <= 0 && (
                            <tr>
                                <td
                                    colSpan="4"
                                    className="text-center negro-dark"
                                    style={{ fontWeight: "600" }}
                                >
                                    El carrito de compras esta vacío
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="container-total-btn-carrito col-12 col-lg-3 d-flex flex-column align-items-end">
                <div className="txt-total-carrito w-100 mb-3">
                    Total del carrito
                </div>
                <div className="total-carrito">
                    <RenderCurrencySimple value={total_carrito} />
                </div>
                <div className="w-100 d-flex flex-column flex-md-row flex-lg-column align-items-center justify-content-center mt-5 mt-lg-0">
                    {/* {!isAuthenticated() ? (
                        <Link
                            to={{
                                pathname: "/login",
                                state: {
                                    from: location,
                                },
                            }}
                            className="a-remove-fromat btn-comprar-carrito"
                            disabled={fields.length <= 0 || total_carrito <= 0}
                        >
                            Comprar
                        </Link>
                    ) : (
                    )} */}
                    <button
                        type="submit"
                        className="btn-comprar-carrito"
                        disabled={fields.length <= 0 || total_carrito <= 0}
                    >
                        Comprar
                    </button>
                    {empresa && (
                        <Link
                            to={`/tienda/${empresa.slug}`}
                            className="btn-volver-carrito"
                        >
                            Volver a la tienda
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
};

let PasoUnoForm = (props) => {
    const {
        handleSubmit,
        cambiarCantidad,
        quitar,
        productos,
        empresa,
        total,
        location,
    } = props;
    return (
        <div className="row">
            <div className="container-tienda-pago mb-5">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-12 px-0">
                            <div className="form-group">
                                <div className="pago-titulo d-flex flex-column flex-md-row flex-lg-row align-items-center justify-content-between mb-3 pb-1">
                                    <span>Detalles del pedido</span>
                                    {/* {empresa && (
                                        <Link to={`/tienda/${empresa.slug}`} className="btn btn-outline-primary btn-lg mb-2 d-none d-md-flex d-lg-flex">Continuar comprando</Link>
                                    )} */}
                                </div>
                                <FieldArray
                                    name="productos"
                                    component={renderProductos}
                                    productos={productos}
                                    cambiarCantidad={cambiarCantidad}
                                    quitar={quitar}
                                    empresa={empresa}
                                    location={location}
                                />
                            </div>
                            {/* <div className="d-flex flex-column flex-md-row flex-lg-row justify-content-center btn-form">

                            </div> */}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

PasoUnoForm = reduxForm({
    form: "PagoForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(PasoUnoForm);

PasoUnoForm = connect((state) => {
    const store = state.pago;
    const form = state.form.PagoForm;
    const storeCarrito = state.carrito_compras;
    const empresa = storeCarrito ? storeCarrito.empresa : null;
    // Carga el listado de productos a comprar desde el state
    const productos_carrito =
        storeCarrito && storeCarrito.productos ? storeCarrito.productos : [];
    if (form.values && !form.values.productos) {
        form.values.productos = productos_carrito.slice();
    }
    const productos = form.values.productos;
    return {
        productos,
        empresa,
    };
})(PasoUnoForm);

export default PasoUnoForm;
