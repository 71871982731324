import React, { Component } from 'react'
import PropTypes from 'prop-types'
import "./completar_perfil.css"
import DatosPerfilForm from './DatosPerfilForm'
import LoadMask from "Utils/LoadMaskTO";

export default class CompletarPerfil extends Component {
    componentDidMount() {
        this.props.inicializarFormCompletarDatos();
    }

    render() {
        //  State
        const { loader, registro_completo } = this.props;

        //  Bind
        const { completarDatosRegistro, registroCompleto, setRegistroCompleto } = this.props;
        return (
            <div id="COMPLETAR-PERFIL">
                <div className="h-100 row d-flex align-items-center justify-content-center">
                    <div className="col-12 col-md-6 col-lg-4">
                        { !registro_completo ? (
                            <div className="card">
                                <LoadMask loading={loader} dark blur>
                                    <div className="card-header">
                                        <img className="icon" src={require("assets/img/icons/perfil.png")} alt="icon-login"/>
                                        <div className="card-title">Completar perfil</div>
                                    </div>
                                    <div className="card-body">
                                        <DatosPerfilForm onSubmit={(data) => {completarDatosRegistro(data)}} setRegistroCompleto={setRegistroCompleto}/>
                                    </div>
                                </LoadMask>
                            </div>
                        ): (
                            <div className="card card-exito">
                                <LoadMask loading={loader} dark blur>
                                    <div className="card-body">
                                        <div className="d-flex flex-column align-items-center justify-content-center">
                                            <img className="icon-exito" src={require("assets/img/icons/exito2.png")} alt="icon-login"/>
                                            <h5 className="txt-exito">Felicidades te has registrado exitosamente</h5>
                                            <button type="button" className="btn btn-login m-1 align-self-center" onClick={() => registroCompleto(this.props.location)}>Finalizar</button>
                                        </div>
                                    </div>
                                </LoadMask>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}
