import React, { Component } from "react";
import PropTypes from "prop-types";
import "./perfil.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { PerfilComponent } from "./componentesPerfil";

export default class Perfil extends Component {
    static propTypes = {};

    constructor(props) {
        super(props);

        this.state = {
            tabIndex: 0,
        };
    }

    componentDidMount() {
        this.props.getInfoUser();
    }

    changeTabIndex = (index) => {
        this.setState({ tabIndex: index });
    };

    openModalPerfil = () => {
        this.props.setModalPerfil(true);
    };
    closeModalPerfil = () => {
        this.props.setModalPerfil(false);
    };

    openModalPassword = () => {
        this.props.setModalChangePassword(true);
    };
    closeModalPassword = () => {
        this.props.setModalChangePassword(false);
    };

    render() {
        //  State
        const {
            datos_perfil,
            modal_perfil,
            loader,
            modal_password,
        } = this.props;
        //  Bind
        const { logOut, update, changePassword } = this.props;
        return (
            <div id="PERFIL">
                <Tabs
                    className="container-tabs"
                    selectedIndex={this.state.tabIndex}
                    onSelect={this.changeTabIndex}
                >
                    <div className="row">
                        <div className="col-12 col-md-4 col-lg-3">
                            <div className="card">
                                <div className="card-header">
                                    <img
                                        className="icon"
                                        src={require("assets/img/icons/perfil.png")}
                                        alt="icon-login"
                                    />
                                    <div className="card-title">Mi cuenta</div>
                                </div>
                                <div className="card-body">
                                    <TabList>
                                        <Tab>Perfil</Tab>
                                        <Tab>Mis compras</Tab>
                                        <Tab>Vender en compralo</Tab>
                                    </TabList>
                                    <h5 className="perfil-tab" onClick={logOut}>
                                        Salir
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-8 col-lg-9 mt-3 mt-md-0 mt-lg-0">
                            <TabPanel>
                                <PerfilComponent
                                    loader={loader}
                                    usuario={datos_perfil}
                                    modal_perfil={modal_perfil}
                                    closeModal={this.closeModalPerfil}
                                    openModal={this.openModalPerfil}
                                    update={update}
                                    // password
                                    modal_password={modal_password}
                                    closeModalPassword={this.closeModalPassword}
                                    openModalPassword={this.openModalPassword}
                                    changePassword={changePassword}
                                />
                            </TabPanel>
                            <TabPanel>
                                <h2>Mis compras</h2>
                            </TabPanel>
                            <TabPanel>
                                <h2>Vender en compralo</h2>
                            </TabPanel>
                        </div>
                    </div>
                </Tabs>
            </div>
        );
    }
}
