import { connect } from "react-redux";
import Perfil from "./Perfil";
import { logOut } from "../../../redux/modules/cuenta/login";
import { actions } from "../../../redux/modules/cuenta/profile";

const ms2p = (state) => ({
    ...state.profile,
});

const md2p = {
    logOut,
    ...actions,
};

export default connect(ms2p, md2p)(Perfil);
