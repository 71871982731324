import React from "react";
import { Field, reduxForm } from "redux-form";
import { renderField } from "Utils/renderField/renderField";

const validate = (values) => {
    let errors = {};

    if (!values.old_password) errors.old_password = "Campo requerido";
    if (!values.password) errors.password = "Campo requerido";

    if (!values.confir_password) errors.confir_password = "Campo requerido";
    else {
        if (values.password != values.confir_password)
            errors.confir_password = "Las contraseñas no coinciden";
    }

    return errors;
};

const ChangePasswordForm = (props) => {
    const { handleSubmit, closeModal } = props;
    return (
        <form
            name="changePasswordForm"
            className="form-validate mb-lg body-modal-perfil"
            onSubmit={handleSubmit}
        >
            <div className="row">
                <div className="col-12">
                    <div className="form-group has-feedback">
                        <label htmlFor="old_password">Contraseña actual</label>
                        <Field
                            autoFocus={true}
                            name="old_password"
                            label="Contraseña actual"
                            component={renderField}
                            type="password"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group has-feedback">
                        <label htmlFor="password">Nueva contraseña</label>
                        <Field
                            name="password"
                            label="Nueva contraseña"
                            component={renderField}
                            type="password"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group has-feedback">
                        <label htmlFor="confir_password">
                            Confirmar contraseña
                        </label>
                        <Field
                            name="confir_password"
                            label="Confirmar contraseña"
                            component={renderField}
                            type="password"
                            className="form-control"
                        />
                    </div>
                </div>
            </div>

            <div className="buttons-box">
                <button
                    type="button"
                    className="btn btn-tertiary m-1 align-self-center"
                    onClick={closeModal}
                >
                    Cancelar
                </button>
                <button
                    type="submit"
                    className="btn btn-primary m-1 align-self-center"
                >
                    Guardar
                </button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: "changePasswordForm",
    validate,
})(ChangePasswordForm);
