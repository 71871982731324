import React from'react'
import Select from 'react-select';
import classNames from 'classnames';

export const renderSelectField = (props) => {
    const {
        input, options, labelKey='label', valueKey='value', placeholder, onCambio, disabled,
        isMulti, isClearable, isSearchable,
        meta: { touched, error, dirty }
    } = props;
    const invalid = (dirty && error) || (touched && error);
    let value = input.value;
    if (value !== null && value !== undefined) {
        const _find = {};
        _find[valueKey] = value;
        value = _.find(options, _find);
        value === undefined && (value = null);
    }
    return (
        <React.Fragment>
            <Select
                className={classNames('form-control', { 'is-invalid': invalid })}
                isMulti={isMulti}
                isClearable={isClearable}
                isSearchable={isSearchable}
                backspaceRemovesValue={false}
                options={options}
                placeholder={placeholder || 'Seleccione...'}
                onChange={(value) => {
                    input.onChange(value ? value[valueKey] : null);
                    !! onCambio && onCambio(value ? value[valueKey] : null);
                }}
                value={value}
                isDisabled={disabled}
            />
            {invalid && (
                <div className="invalid-feedback">{error}</div>
            )}
        </React.Fragment>
    )
};
