import "./navbar.css";
import React from "react";
import { CarritoCompra } from "Utils/CarritoCompra";
import { Link } from "react-router-dom";
import BuscadorTO from "../ListadoProductos/BuscadorComponent";

const Navbar = (props) => (
    <nav className="navbar" id="NAVBAR-TO">
        <div
            className="align-items-start align-items-md-center align-items-lg-center container-navbar d-flex flex-column flex-md-row flex-lg-row justify-content-between w-100"
            style={{ background: "none" }}
        >
            <div className="container-brand">
                <Link to="/" className="navbar-brand">
                    <img
                        src={require(`assets/img/compralo_negro.png`)}
                        width="100"
                        height="50"
                        className="d-inline-block align-top"
                        alt=""
                    />
                </Link>
                {props.mostrar_iconos && (
                    <div id="CARRITO-MOVIL">
                        <CarritoCompra />
                    </div>
                )}
            </div>
            {props.buscador_tienda && (
                <BuscadorTO
                    buscar_tienda={props.searchChange}
                    search_tienda={props.search}
                    setSearch={props.setSearch}
                />
            )}

            <div className="navbar-rigth">
                <div className="d-flex align-items-center justify-content-end">
                    <Link to="/" className="link-tiendas">
                        <span>Ver tiendas</span>
                        <img
                            src={require("assets/img/icons/tiendas.png")}
                            width="45"
                            alt=""
                        />
                    </Link>
                    {props.mostrar_iconos && <CarritoCompra />}
                </div>
                {/* <div
                    className="d-flex align-items-center"
                    style={{ marginLeft: "25px", height: "50px" }}
                >
                    {!props.is_authenticated ? (
                        <Link
                            to={{
                                pathname: "/login",
                                state: {
                                    from: props.location,
                                },
                            }}
                            className="link-mi-cuenta"
                        >
                            <span>Mi cuenta</span>
                            <i className="fa fa-user" />
                        </Link>
                    ) : (
                        <Link
                            to="/perfil"
                            className={`link-mi-cuenta ${
                                props.usuario && props.usuario.avatar_url
                                    ? "img"
                                    : ""
                            }`}
                        >
                            {props.usuario ? (
                                <span>{props.usuario.first_name}</span>
                            ) : (
                                <span>Mi cuenta</span>
                            )}
                            {props.usuario &&
                                (props.usuario.avatar_url ? (
                                    <img
                                        src={props.usuario.avatar_url}
                                        alt="avatar"
                                        className="avatar"
                                    />
                                ) : (
                                    <i className="fa fa-user" />
                                ))}
                        </Link>
                    )}
                </div> */}
            </div>
        </div>
    </nav>
);

export default Navbar;
