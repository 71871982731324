import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import { Route, Redirect } from "react-router-dom";
import Navbar from './common/components/Navbar'
import Footer from './common/components/Footer';
import { actions } from './redux/modules/productos';
import SideBar from './common/components/SideBar'
import { getMe } from './redux/modules/usuario/usuario';
import usuario from "../../../frontend/src/js/redux/modules/usuario/usuario";

function isAuthenticated() {
    return localStorage.getItem("token");
}

class TiendaOnlineRouteBase extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             width: window.innerWidth
        }
    }

    componentWillMount(props) {
        // this.props.setRuta(window.location.href);
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.reportWindowSize);
        this.props.getMe();
    }
  
    componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
      window.removeEventListener('resize', this.reportWindowSize);
    }

    handleScroll(event) {
        let header = document.getElementById("NAVBAR-TO");
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            if(header) {
                header.classList.add("sticky");
            }
        }else {
            if(header) {
                header.classList.remove("sticky");
            }
        }
    }

    reportWindowSize = (event) => {
        this.setState({width: window.innerWidth})
    }

    render() {
        const { component: Component , buscador_tienda, search, mostrar_iconos, vista, usuario, ...rest } = this.props;
        //  Bind
        const { searchChange, setSearch } = this.props;

        return (
            <Route
                {...rest}
                render={props => (
                    <Fragment>
                        <SideBar vista={vista ? vista : null} isMobile={this.state.width <= 767}/>
                        <Navbar
                            is_authenticated={isAuthenticated() ? true : false}
                            location={props.location}
                            mostrar_iconos={mostrar_iconos ? true : false}
                            buscador_tienda={buscador_tienda}
                            searchChange={searchChange}
                            setSearch={setSearch}
                            search={search}
                            usuario={usuario}/>
                        <div id="contenido" style={{display: "block", position: "relative", flex: "1"}}>
                            {/* <Sidebar navbarCollapse={navbarCollapse} /> */}
                            <Component {...props} />
                        </div>

                        {/* FOOTER */}
                        <Footer/>
                    </Fragment>
                )}
            />
        );
    }
}

const mstp = state => ({
    search: state.productos.search,
    usuario: state.usuario.me
});
const mdtp = {
    searchChange: actions.searchChange,
    setSearch: actions.setSearch,
    getMe: getMe,
};

const RouteTiendaOnline = connect(
    mstp,
    mdtp
)(TiendaOnlineRouteBase);

export default RouteTiendaOnline;
