import { connect } from 'react-redux'
import CompletarPerfil from './CompletarPerfil'
import { completarDatosRegistro, registroCompleto, setRegistroCompleto, inicializarFormCompletarDatos } from '../../../redux/modules/usuario/usuario'

const ms2p = (state) => ({
    loader: state.usuario.loader,
    registro_completo: state.usuario.registro_completo
})

const md2p = {
    completarDatosRegistro,
    registroCompleto,
    setRegistroCompleto,
    inicializarFormCompletarDatos,
}

export default connect(ms2p, md2p)(CompletarPerfil);