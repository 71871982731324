import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { initialize as initializeForm } from "redux-form";
import { NotificationManager } from "react-notifications";
import { api } from "api";
import history from "../../../utility/history";
import { setMe } from "../usuario/usuario";

const SUBMIT = "LOGIN_SUBMIT";
const LOADER = "LOGIN_LOADER";

export const constants = {
    SUBMIT,
};

const endpointRedesSociales = "rest-auth";

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

// ------------------------------------
// Actions
// ------------------------------------

export const onSubmit = (data = {}, location) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.post("usuarios_online/token", data)
        .then((response) => {
            if (response) {
                localStorage.setItem("token", response.token);
                NotificationManager.success(
                    "Bienvenido(a) a COMPRALO.GT",
                    "Éxito",
                    3000
                );

                //  Redireccionamiento al la página donde se ingreso al login
                if (response.me.registro_completo) {
                    dispatch(setMe(response.me));
                    if (location.state) {
                        if (location.state.from) {
                            history.push(location.state.from.pathname);
                        } else {
                            history.push("/");
                        }
                    } else {
                        history.push("/");
                    }
                } else {
                    history.push("/completar-perfil", location.state);
                }
            }
        })
        .catch((err) => {
            if (err) {
                NotificationManager.error(
                    err.detail || "Credenciales incorrectas, vuelva a intentar",
                    "ERROR",
                    0
                );
            } else {
                NotificationManager.error(
                    "Credenciales incorrectas, vuelva a intentar",
                    "ERROR",
                    0
                );
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const onLoginSocial = (responseSocial, location, provider) => (
    dispatch
) => {
    dispatch(setLoader(true));
    if (responseSocial.accessToken) {
        api.post(
            `${endpointRedesSociales}/${provider}`,
            { access_token: responseSocial.accessToken },
            {},
            false
        )
            .catch((err) => {})
            .then((resp) => {
                if (resp) {
                    //  Se registra el token en el localstorage
                    localStorage.setItem("token", resp.key);

                    //  Se llama la accion para obtener la info del usuario
                    dispatch(getMe(location));

                    NotificationManager.success(
                        "Bienvenido(a) a COMPRALO.GT",
                        "Éxito",
                        3000
                    );
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    } else {
        NotificationManager.error(
            `No fue posible registrar el usuario con ${provider}, intenta más tarde`,
            "ERROR",
            0
        );
        dispatch(setLoader(false));
    }
};

export const getMe = (location) => (dispatch) => {
    api.get("usuarios_online/me", {})
        .catch(() => {
            localStorage.removeItem("token");
        })
        .then((me) => {
            if (me) {
                dispatch(setMe(me));
                //  Redireccionamiento al la página donde se ingreso al login
                if (me.registro_completo) {
                    if (location.state) {
                        if (location.state.from) {
                            history.push(location.state.from.pathname);
                        } else {
                            history.push("/");
                        }
                    } else {
                        history.push("/");
                    }
                } else {
                    history.push("/completar-perfil", location.state);
                }
            }
        });
};

export const logOut = () => (dispatch) => {
    api.post("/rest-auth/logout", {}, {}, false)
        .then(() => {})
        .catch(() => {})
        .finally(() => {
            localStorage.removeItem("token");
            history.push("/");
        });
};

export const actions = {
    onSubmit,
    logOut,
    onLoginSocial,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
};

export const initialState = {
    loader: false,
    me: {},
};

export default handleActions(reducers, initialState);
