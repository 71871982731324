import React from "react";
import { Field, reduxForm } from "redux-form";
import { renderField } from "Utils/renderField/renderField";

const validate = (values) => {
    let errors = {};

    if (!values.email) errors.email = "Campo requerido";
    else {
        if (
            !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)
        ) {
            errors.email = "Correo electrónico invalido";
        }
    }

    if (!values.first_name) errors.first_name = "Campo requerido";

    return errors;
};

const EditarPerfilForm = (props) => {
    const { handleSubmit, closeModal } = props;
    return (
        <form
            name="editarPerfilForm"
            className="form-validate mb-lg body-modal-perfil"
            onSubmit={handleSubmit}
        >
            <div className="row">
                <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group has-feedback">
                        <label htmlFor="email">Correo</label>
                        <Field
                            name="email"
                            label="Correo"
                            component={renderField}
                            type="email"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group has-feedback">
                        <label htmlFor="first_name">Nombre</label>
                        <Field
                            name="first_name"
                            label="Nombre"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group has-feedback">
                        <label htmlFor="last_name">Apellido</label>
                        <Field
                            name="last_name"
                            label="Apellido"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-group has-feedback">
                        <label htmlFor="telefono">Teléfono</label>
                        <Field
                            name="telefono"
                            label="Teléfono"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                </div>
            </div>
            <div className="buttons-box">
                <button
                    type="button"
                    className="btn btn-tertiary m-1 align-self-center"
                    onClick={closeModal}
                >
                    Cancelar
                </button>
                <button
                    type="submit"
                    className="btn btn-primary m-1 align-self-center"
                >
                    Guardar
                </button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: "editarPerfilForm",
    validate,
})(EditarPerfilForm);
